<template>
  <div class="boardpage" :class="['boardpage', className]">
    <ul>
      <li @click="goToPage(navMin)"><img src="@/assets/img/icon_page_ll_off.svg" /></li>
      <li @click="goToPage(pageNum - 1)"><img src="@/assets/img/icon_page_l_off.svg" /></li>
      <li>Page :</li>
      <template v-for="i in navArray" v-bind:key="`nav${i}`">
        <li :class="{ on: pageNum === i }"
           @click="goToPage(i)">{{i}}</li>
      </template>
      <li>of {{pageNum}}</li>
      <li @click="goToPage(pageNum + 1)"><img src="@/assets/img/icon_page_r_off.svg" /></li>
      <li @click="goToPage(navMax)"><img src="@/assets/img/icon_page_rr_off.svg" /></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pageNum: {
      type: Number,
      default: 1,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10,
      required: true
    },
    totalCount: {
      type: Number,
      default: 0,
      required: true
    },
    navSize: {
      type: Number,
      default: 10
    },
    className: {
      type: String,
      required: false
    }
  },
  computed: {
    navArray () {
      return new Array(Math.max(this.navMin, this.navEnd - this.navStart + 1))
        .fill(this.navStart).map((n, i) => n + i)
    },
    navStart () {
      return Math.max(this.pageNum - (this.navSize - 1 - Math.min(Math.floor(this.navSize / 2),
        this.navMax - this.pageNum)), this.navMin)
    },
    navEnd () {
      return Math.min(this.pageNum + Math.max(Math.floor(this.navSize / 2),
        this.navSize - this.pageNum), this.navMax)
    },
    navMin () {
      return 1
    },
    navMax () {
      return Math.floor((this.totalCount - 1) / this.pageSize) + 1
    },
    totalPage () {
      return Math.ceil(this.totalCount / this.pageSize)
    }
  },
  methods: {
    goToPage (no) {
      if (this.navArray.length > 1) {
        this.$emit('goToPage', no)
      }
    }
  }
}
</script>
<style scoped>
.boardpage {margin-bottom: 85px;}
.boardpage ul {display: flex;justify-content: left;align-items: center;gap: 10px;}
.boardpage ul li {cursor: pointer;font-size: 14px;color: #404654;display: flex;align-items: center;justify-content: center;}
.boardpage ul li.on {width: 54px;height: 30px;border-radius: 3px;border: solid 1px #989898;background-color: #fff;color: #404654;}
</style>
<style scoped src="@/styles/common.css"></style>
